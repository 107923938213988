import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: "AIzaSyDCQLMFeXYdzt_0tjycinFDukBB8f9VafE",
    authDomain: "chichat-75b8a.firebaseapp.com",
    databaseURL: "https://chichat-75b8a.firebaseio.com",
    projectId: "chichat-75b8a",
    storageBucket: "chichat-75b8a.appspot.com",
    messagingSenderId: "33875883300",
    appId: "1:33875883300:web:dbfb81e37ce21a3ecfafb1",
    measurementId: "G-M9TJ3WHFFD"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();   //this is for google analytics


export default firebase
